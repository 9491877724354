.navbar_wrapper {
    width: 100%;
    background-color: black; /* Black background */
    position: fixed;
    z-index: 1000;

  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .navbar-logo_wrapper .navabar-logo {
    font-size: 50px;
    font-weight: bold;
    color: #fff; /* White logo for contrast against black background */
  }
  
  .navbar_icons {
    display: flex;
    gap: 80px;
    padding-right: 200px;
  }
  
  .navbar_link {
    text-decoration: none;
    color: #ccc; /* Light gray for normal link state */
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: color 0.3s ease;
  }
  
  .navbar_link:hover,
  .active-link {
    color: #fff; /* White for hovered and active link state */
  }
  