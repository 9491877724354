body, html {
  margin: 0;
  height: 100%;
}

#root, .App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App-content{
  flex: 1 0 auto;
}

.Footer_wrapper {
  background-color: #000;
  color: #fff;
  padding: 20px;
  text-align: center;
}
