.projects {
    padding: 50px;
    text-align: left;
    background-color: white;
    color: #333;
    font-family: sans-serif;
    z-index: 1;
}

.projects h1{
    font-size: 2rem;
    margin-bottom: 20px;
}

.project-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
}

.project-card {
    background-color: #f9f9f9;
    padding: 20px;
    border: 1px solid #000;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.project-card img{
    width: 100%;
    height: 200px;
    border-radius: 5px;
}

.project-card h2{
    font-size: 1.5rem;
    margin-top: 10px;
}

.project-card p{
    margin-top: 10px;
    flex-grow: 1;
}

.buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding-right: 5px;
}

.buttons a {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    color: #fff;
    background-color: #000;
    transition: background-color 0.3s ease-in-out;
    text-decoration: none;
    cursor: pointer;
    margin-right: 1px; /* Add some spacing between buttons */
}


