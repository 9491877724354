.Footer_wrapper {
    background-color: #222222;
    color: #fff;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Footer_tag p,
.Footer_copyright p {
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
    margin-left: 10px;
}

.Footer_links {
    margin-top: 10px;
    display: flex;
    align-items: center;
}

.Footer_links p {
    margin-right: 100px;
}

.Footer_links a {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s ease;
    margin-right: 10px;
}

.Footer_links a:hover {
    color: #ccc;
}