.home {
    padding: 50px;
    text-align: left;
    background-color: white;
    color: #333;
    font-family: sans-serif;
    z-index: 1;
}

.hero h1{
    font-weight: 700;
    color: #000;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Merriweather', serif;
    color: #222; /* Almost Black */
}

.about-me h2, .contact-cta h2 {
    font-weight: 700; /* Bold */
    color: #000; /* Black */
}

p {
    color: #555; /* Grey for body text */
}

/* Adjustments for links or CTA buttons can go here, example: */
button, a {
    color: #1d72b8; /* A shade of blue */
    font-weight: 700; /* Bold */
}

.hero, .about-me, .contact-cta {
    margin-bottom: 50 px;
    font-size: 1.7em;
}

.about-me-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.text-content {
    max-width: 60%;
}

.image-content img {
    max-width: 300px;
    border-radius: 50%;
}

.About_image-content img{
    width: 300px;
    height: 300px;
    border-radius: 50%;
    object-fit: cover; /* This ensures the image covers the container */
    position: relative;
    top: 50px; /* Adjust this value to control the vertical position */
    left: 5px; /* Adjust th*/
    padding-bottom: 20px;
}

.About_image-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}

.technical-skills {
    margin-top: 50px;
}

.icon {
    font-size: 5rem;
}